
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { EventSponsorModel } from "@/models/baEvent";
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import { BAIconsMixin, FormRulesMixin, StatusMixin, StringsMixin, AdminRoutingMixin } from '@/mixins';
import { eventSponsorApi } from '@/api/BAEventsApi';

@Component({
	components: {
		Page,
        FileUploadArea,
	}
})
export default class EventSponsorAdminPage extends Mixins(BAIconsMixin, FormRulesMixin, StatusMixin, StringsMixin, AdminRoutingMixin) {
    loadingEventSponsors: boolean = true;
    eventSponsors: EventSponsorModel[];
    search = "";
    
    mounted() {
        this.loadEventSponsors();
    }
    async loadEventSponsors() {
        this.loadingEventSponsors = true;
        try {
            const response = await eventSponsorApi.queryAll({});
            this.eventSponsors = response.docs;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading Sponsors: ${e}`);
        }
        this.loadingEventSponsors = false;
    }
    get IsLoading(): boolean {
        return this.loadingEventSponsors;
    }

    get Headers(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
            {text: 'Website', value: 'url'},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', width:"25", value: 'data-table-expand'}
        ]
    }

    editTitle: string = "Edit Sponsor";
    backupSponsor: EventSponsorModel;
    editSponsor: EventSponsorModel = new EventSponsorModel;
    validSponsor: boolean;
    editingSponsor: boolean = false;
    onAddSponsor() {
        this.editTitle = "Add Sponsor";
        this.editSponsor = new EventSponsorModel();
        this.editingSponsor = true;
    }
    onEditSponsor(sponsor: EventSponsorModel) {
        this.editTitle = `Edit ${sponsor.name}`;
        this.backupSponsor = new EventSponsorModel;
        Object.assign(this.backupSponsor, sponsor);
        this.editSponsor = sponsor;
        this.editingSponsor = true;
    }
    async onDeleteSponsor(sponsor: EventSponsorModel) {
        if( !confirm(`Delete ${sponsor.name}? This action cannot be undone`) ) return;

        await eventSponsorApi.delete(sponsor);
        this.loadEventSponsors();
    }
    onCancelEditDlg() {
        Object.assign(this.editSponsor, this.backupSponsor);
        this.editingSponsor = false;
    }
    async onSaveEditDlg() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

        await eventSponsorApi.save(this.editSponsor);
        this.editingSponsor = false;
        this.loadEventSponsors();
    }
}
