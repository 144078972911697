
import { StringsMixin } from '@/mixins';
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class RecruitingProfileInfo extends Mixins(StringsMixin) {
	@Prop({ required: true }) label: string;
	@Prop({ required: true, default: undefined}) data: any;
	@Prop({ type: Boolean, default: true }) private dataCheck;
	@Prop({ type: Boolean }) always;
	@Prop({ default: "4" }) labelCols: string;
	@Prop({ default: "0" }) appendCols: string;
	@Prop({ type: Boolean }) link;
	@Prop({ type: Boolean }) large;
	@Prop({ type: Boolean }) wrap;
	@Prop({ type: Boolean }) clipboard;
	@Prop({ type: String, default: undefined}) email: string;

	get DisplayData(): boolean {
		if( this.always ) return true;
		if( this.HasEmail ) return true;
		return this.HasData && this.dataCheck;
	}

	get Data(): string {
		if( typeof(this.data) === 'string' ) return this.data;
		if( typeof(this.data) === 'boolean' ) return this.data? 'true':'false';
		if( typeof(this.data) === 'number' ) return this.data.toString();
		if( this.data instanceof Date ) return this.formatDatePretty(this.data);
		if( this.data instanceof Array ) return this.data.toString();
		return typeof(this.data);
	}
	get HasData(): boolean {
		return this.IsNotEmpty(this.data);
	}
	get HasEmail(): boolean {
		return this.IsNotEmpty(this.email);
	}
}
