import { CrudApi } from './CrudApi';
import { EventAssessmentDataModel } from '../models/athlete/EventAssessmentDataModel';
import { PaginatedResponse, RepositoryQuery, QueryOptions } from '@/../types';
import { AthleteAssessmentDataModel, BAEventModel } from '@/models';
import { athleteAssessmentDataApi } from './AthleteAssessmentDataApi';

class EventAssessmentDataApi extends CrudApi<EventAssessmentDataModel> {
	constructor(){
		super('eventAssessment', (obj) => new EventAssessmentDataModel().load(obj));
	}

    async findOne(query): Promise<EventAssessmentDataModel> {
        const options: QueryOptions = { limitPerPage: 1 };
        const response: PaginatedResponse<EventAssessmentDataModel> = await eventAssessmentDataApi.queryAll(query, options);
        if( response.docs.length === 0 ) return undefined;
        await response.docs[0].initialize();
        return response.docs[0];
    }
    async findOneByAthleteAndEvent(athleteId: string, eventId: string): Promise<EventAssessmentDataModel> {
        const query: RepositoryQuery<EventAssessmentDataModel> = { $match: {athleteId, eventId} };
        return await this.findOne(query);
    }

    async createOneForAthleteAndEvent(athleteId: string, event: BAEventModel): Promise<EventAssessmentDataModel> {
        const obj: EventAssessmentDataModel = new EventAssessmentDataModel();
        obj.athleteId = athleteId;
        obj.eventId = event.id;
        
        // create the physical assessment data entry
        const physicalAssessment: AthleteAssessmentDataModel = new AthleteAssessmentDataModel();
        physicalAssessment.athleteId = athleteId;
        physicalAssessment.assessmentDate = new Date(event.date);
        const newPhysicalAssessment = await athleteAssessmentDataApi.save(physicalAssessment);
        obj.assessmentId = newPhysicalAssessment.id;
        obj.physical = newPhysicalAssessment;

        return await this.save(obj);
    }
}

export const eventAssessmentDataApi = new EventAssessmentDataApi();