
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { AppHostnameMixin, BAIconsMixin, FormRulesMixin, PaginatedTableMixin, RoutingMixin, StatusMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import { BAEventModel, EventLocationModel } from '@/models/baEvent';
import { notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import { RepositoryQuery, QueryOptions } from '@/../types';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { BAEventAdminDetail, BAEventDetail } from '@/../types/constants/admin.routes'
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import BAEventShare from '@/views/admin/BAEventShare.vue'
import { AgreementFile } from '@/models/file/FileModel';
import { baEventApi, eventLocationApi } from '@/api/BAEventsApi';
import { ObjectCache } from '@/helpers/object-cache';

@Component({
	components: {
		Page,
        FileUploadArea,
		TimePickerDialog,
        BAEventShare
	}
})
export default class BAEventsAdminDashboard extends Mixins(StringsMixin, VuetifyMixin, BAIconsMixin, RoutingMixin, AppHostnameMixin, StatusMixin, FormRulesMixin, PaginatedTableMixin){
    baEvents: Array<BAEventModel>;
    locationsCache: ObjectCache<EventLocationModel> = new ObjectCache<EventLocationModel>();
    allLocations: Array<EventLocationModel> = [];

    isLoadingLocation: boolean = false;
    selectLocation: EventLocationModel = new EventLocationModel;
    showArchived: boolean = false;
    showAll: boolean = false;

    mounted() {
        this.tableOptions.sortBy = ['date'];
        this.tableOptions.sortDesc = [true];
        this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];

        this.loadLocations();
    }
    async loadTable() {
        this.isLoading = true;
        this.isLoaded = false;
        try {
			let query: RepositoryQuery<BAEventModel> = this.TableQuery<BAEventModel>(this.search, ['name', 'notes']);
			if( !this.showAll) {
                query.$match = {...query.$match, ...{ archived: this.showArchived } };
            }
            const options: QueryOptions = this.TableQueryOptions;
            let response = await baEventApi.queryAll(query, options);
            if( response.count === 0 ) {
                query = this.TableQuery<BAEventModel>(this.search, ['name', 'description', 'notes', 'location']);
                response = await baEventApi.queryAll(query, options);
            }
            this.baEvents = await Promise.all(response.docs.map(async(e) => {
                const event = new BAEventModel().load(e);
                await event.loadEx(this.locationsCache);
                return event;
            }));
            this.dataItemsCount = response.total;
        } catch(e) {
            notificationStore.snackbarShowError(`Error loading BA events: ${e}`);
        }
        this.isLoaded = true;
        this.isLoading = false;
    }
    get TableLoading(): boolean {
        return this.isLoading || this.isLoadingLocation;
    }
    get PageLoading(): boolean {
        return this.TableLoading || this.isLoading;
    }

    get TableHeaders(): Array<DataTableHeader<any>> {
        let headers: Array<DataTableHeader<any>> = [
            {text: 'Name', value: 'name'},
            {text: 'Location', value: 'location'},
            {text: 'Date', value: 'date'},
            {text: 'Participants', value: 'participants', sortable: false},
            {text: '', value: 'view', sortable: false},
        ];
        if( this.IsLargeScreen ) {
            headers.push({text: '', value: 'actions', sortable: false });
            headers.push({text: '', value: 'data-table-expand', sortable: false});
        }
        return headers;
    }

    async loadLocations() {
        this.isLoadingLocation = true;
        const response = await eventLocationApi.queryAll({});
        this.allLocations = response.docs;
        this.locationsCache.setMultiple(this.allLocations);
        this.isLoadingLocation = false;
    }
	get Locations(): Array<EventLocationModel> {
		return this.allLocations;
	}

    onToggleShowArchived() {
        this.showAll = false;
        this.showArchived = !this.showArchived;
        this.updateTable();
    }
    onToggleShowAll() {
        this.showAll = !this.showAll;
        this.showArchived = this.showAll;
        if( !this.showAll ) {
            this.search = '';
        }
        this.updateTable();
    }

    editTitle: string = "Edit Event";
    backupEvent: BAEventModel;
    editEvent: BAEventModel = new BAEventModel;
	editEventDate: Date;
	editEventTime: string;
    editingEvent: boolean = false;
	startEditEvent() {
		this.editEventDate = new Date(this.editEvent.DateStr);
		this.editEventTime = this.editEvent.TimeStr;
		this.unlimitedCapacity = (this.editEvent.capacity < 0);
		this.selectLocation = this.editEvent.Location;
		this.editingEvent = true;
	}
    onAddEvent() {
        this.editTitle = "Add Event";
        this.editEvent = new BAEventModel();
		this.startEditEvent();
    }
    onEditEvent(baEvent: BAEventModel) {
        this.editTitle = `Edit ${baEvent.name}`;
        this.backupEvent = new BAEventModel().load(baEvent);
        this.editEvent = baEvent;
        if( this.IsEmpty( this.editEvent.dataLabels )) {
            this.editEvent.dataLabels = ['First Name', 'Last Name', 'Email', 'Comments'];
        }
		this.startEditEvent();
    }
	onViewEvent(baEvent: BAEventModel) {
		this.$router.push({
			name: BAEventAdminDetail,
			params: { eventId: baEvent.id }			
		});
	}
	onPreviewEvent(baEvent: BAEventModel) {
		this.$router.push({
			name: BAEventDetail,
			params: { eventId: baEvent.id }			
		});
	}
    onAttendeeCheckIn(baEvent: BAEventModel) {
        const url = `${this.AdminLInk}/baevent-redeem?userId=none&eventId=${baEvent.id}&ticketId=all`;
        this.gotoUrl(url);
    }
    async onDeleteEvent(baEvent: BAEventModel) {
        if( !confirm(`Delete ${baEvent.name}? This action cannot be undone`) ) return;

        await baEventApi.delete(baEvent);
        this.updateTable();
    }
    async onEventArchive(baEvent: BAEventModel) {
        await baEventApi.patch({id: baEvent.id, archived: baEvent.archived? false : true});
        this.updateTable();
    }

    newAgreement: AgreementFile = new AgreementFile('My Agreement');
    newAgreementIndex: number = 1;
    agreementUnderPreview: AgreementFile;
    agreementPreview: boolean = false;
    onRemoveAllAgreements() {
        this.editEvent.agreements = [];
    }
    onRemoveAgreement(index: number) {
        this.editEvent.agreements.splice(index, 1);
    }
    async onNewAgreement() {
        if( this.IsEmpty(this.newAgreement) ) return;
        this.editEvent.agreements.push(this.newAgreement);
        await this.saveEvent(this.editEvent);
        this.newAgreementIndex++;
        this.newAgreement = new AgreementFile(`My Agreement ${this.newAgreementIndex}`);
    }
    async onPreviewAgreement(agreement) {
        this.agreementUnderPreview = agreement;
        await this.agreementUnderPreview.read();
        this.agreementPreview = true;
    }
    onClosePreview() {
        this.agreementPreview = false;
    }

    newDataLabel: string = '';
    onRemoveAllDataLabels() {
        this.editEvent.dataLabels = [];
    }
    onRemoveDataLabel(index: number) {
        this.editEvent.dataLabels.splice(index, 1);
    }
    onNewDataLabel() {
        if( this.IsEmpty(this.newDataLabel) ) return;
        if( this.editEvent.dataLabels.includes(this.newDataLabel) ) return;

        const commentsIndex = this.editEvent.dataLabels.indexOf('Comments');
        if( commentsIndex > 0 ) {
            this.editEvent.dataLabels.splice(commentsIndex, 0, this.newDataLabel);
        } else {
            this.editEvent.dataLabels.push(this.newDataLabel);
        }
    } 

    unlimitedCapacity: boolean;
	onChangeUnlimitedCapacity() {
		this.editEvent.capacity = this.unlimitedCapacity? -1 : (( !this.backupEvent || this.backupEvent.capacity < 0 )? 100 : this.backupEvent.capacity );
	}

    onCancelEditDlg() {
        if( this.backupEvent ) {
            Object.assign(this.editEvent, this.backupEvent);
        }
        this.clearStatus();
        this.editingEvent = false;
    }
    savingEvent: boolean = false;
    async saveEvent(baEvent: BAEventModel) {
        this.savingEvent = true;
        await baEvent.loadEx(this.locationsCache);
        baEvent.notes = `${baEvent.name}, ${baEvent.locationDetails.name}, ${baEvent.locationDetails.Location}`;
        await baEventApi.save(baEvent);
        this.updateTable();
        this.savingEvent = false;
    }
    async onSaveEditDlg() {
        if( !this.$refs.form.validate() ) {
            this.setError( "Event creation incomplete. Please complete all of the required fields" );
            this.savingEvent = false;
            return;
        }

		this.editEvent.date = new Date(this.formatDateSlashesYYYYMMDD(this.editEventDate, true) + ` ${this.editEventTime}`);
		this.editEvent.location = this.selectLocation? this.selectLocation.id : '';

        await this.saveEvent(this.editEvent);
        this.editingEvent = false;
    }

	get EarliestTime(): Date {
		return new Date(`${this.formatDateSlashesYYYYMMDD(this.editEvent.date)} 7:00 am`);
	}

    $refs:{ form: HTMLFormElement }

	onEditSponsors() {
		this.$router.push('/baeventsponsors');
	}
	onEditLocations() {
		this.$router.push('/baeventlocations');
	}
}
