/* AthletesCacheMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { StringsMixin } from './StringsMixin';
import { ObjectCache } from '@/helpers/object-cache';
import { AthleteProfileModel } from '@/models';
import { athleteApi } from '@/api/AthleteApi';

@Component
export class AthletesCacheMixin extends Mixins(StringsMixin) {
	athletesCache: ObjectCache<AthleteProfileModel> = new ObjectCache<AthleteProfileModel>();

	async AthleteRetrieve(athleteId: string): Promise<AthleteProfileModel> {
		let athlete = this.athletesCache.get(athleteId);
		if( this.IsEmpty(athlete) ) {
			athlete = await athleteApi.publicFindById(athleteId);
			this.athletesCache.set(athlete);
		}
		return athlete;
	}
}
