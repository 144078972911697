
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { VuetifyMixin, BAIconsMixin, PaginatedTableMixin, CoachRoutingMixin, ColorsMixin, FormRulesMixin, AuthMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { notificationStore } from '@/store';
import { BAEventModel, EventAssessmentDataModel, EventParticipantModel, Sprint, JumpDJ, JumpCMJ, TeamModel, YoYo } from '@/models';
import RecruitingProfileInfo from '@/components/profile/recruiting/RecruitingProfileInfo.vue';
import { BaseSoccerPosition, DominantSide } from '@/../types/enums';
import { Gender } from '@/../types/enums';
import TeamMiniCard from '@/components/ui/TeamMiniCard.vue';
import { eventAssessmentDataApi } from '@/api/EventAssessmentDataApi'
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';
import { athleteApi } from '@/api/AthleteApi';
import { baEventApi } from '@/api/BAEventsApi';
import { ObjectCache } from '@/helpers/object-cache';
import { isDateWithinRange } from '@/helpers'
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { cloneDeep, isEmpty } from 'lodash';
import { AthletesCacheMixin } from '@/mixins/AthletesCacheMixin';

@Component({
	components: {
		Page,
		RecruitingProfileInfo,
		TeamMiniCard,
	}
})
export default class PhysicalEventsDataEntryPage extends Mixins(VuetifyMixin, BAIconsMixin, PaginatedTableMixin, CoachRoutingMixin, ColorsMixin, FormRulesMixin, AuthMixin, AthletesCacheMixin) {
	Gender = Gender;
	Position = BaseSoccerPosition;
	DominantSide = DominantSide;

	mounted(): void {
		this.tableOptions.itemsPerPage = 10;
		this.tableOptions.sortBy = ['group','idcolor'];
		this.tableOptions.sortDesc = [false, false];
		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];

		this.backupCheckin = {
			checkedIn: false,
			weight: 0,
			height: 0,
			sittingHeight: 0,
			gender: Gender.Other,
			position: BaseSoccerPosition.Defender,
			dominant: DominantSide.Ambidextrous,
			notes: '',
		}
		this.backupSprints = {
			checkedIn: false,
			sprints: [],
			bestSprint: 0,
			notes: '',
		}
		this.backupJumpsDJ = {
			checkedIn: false,
			jumps: [],
			bestJumpDJ: 0,
			notes: '',
		}
		this.backupJumpsCMJ = {
			checkedIn: false,
			jumps: [],
			bestJumpCMJ: 0,
			notes: '',
		}
		this.backupYoYo = {
			checkedIn: false,
			yoyos: [],
			bestYoYo: 0,
			notes: '',
		}
		this.loadEvent();
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Athlete', value: 'name', sortable: false },
			{ text: 'Group', value: 'group', sortable: false },
			{ text: 'Jersey', value: 'idnumber', align:'center', sortable: false },
			{ text: 'Checkin', value: 'checkedIn', align:'center', sortable: false, width: '50' },
			{ text: 'Sprint', value: 'sprints', align:'center', sortable: false, width: '50' },
			{ text: 'DJ', value: 'jumpsDJ', align:'center', sortable: false, width: '50' },
			{ text: 'CMJ', value: 'jumpsCMJ', align:'center', sortable: false, width: '50' },
			{ text: 'YoYo', value: 'yoyo', align:'center', sortable: false, width: '50' },
			{ text: 'Status', value: 'status', align:'center', sortable: false, width: '50' },
			{ text: '', value: 'actions', sortable: false },
			{ text: '', value: 'tags', sortable: false, width: '0' },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}
		return headers;
	}

	get TableItems(): Array<EventAssessmentDataModel> {
		const headerRow: EventAssessmentDataModel = new EventAssessmentDataModel();
		headerRow.assessmentId = "header-row";
		return [headerRow, ...this.athleteAssessments];
	}

	filteredParticipants: Array<EventParticipantModel> = [];
	athleteAssessments: Array<EventAssessmentDataModel> = [];
	teamsCache: ObjectCache<TeamModel> = new ObjectCache<TeamModel>();
	orgsCache: ObjectCache<OrganizationModel> = new ObjectCache<OrganizationModel>();
	async loadAthletes() {
		this.isLoading = true;
		this.isLoaded = false;
		if( this.IsEmpty(this.currentEvent) ) return;

		// load the athlete event assessments for each of the attendees
		this.athleteAssessments = [];
		this.athleteAssessments = await Promise.all(
			this.filteredParticipants.slice(this.FirstTableItemIndex, this.LastTableItemIndex).map( async (attendee: EventParticipantModel) => {
				var isNewPhysical: boolean = false;
				// load the assessment for this athlete and event
				var assessment = await eventAssessmentDataApi.findOneByAthleteAndEvent(attendee.attendeeId, this.currentEvent.id);
				if( this.IsEmpty(assessment) ) {
					// if there is no previous assessment, create a new one
					isNewPhysical = true;
					assessment = await eventAssessmentDataApi.createOneForAthleteAndEvent(attendee.attendeeId, this.currentEvent);
				}

				// populate the assessment
				assessment.athlete = await this.AthleteRetrieve(attendee.attendeeId);

				assessment.event = this.currentEvent;
				const ticket = this.currentEvent.Tickets.find(t => t.id === attendee.ticketId);
				if( this.IsNotEmpty(ticket) ) assessment.group = ticket.name;
				if( this.IsEmpty(assessment.athlete) ) return assessment;
				
				// load athlete team
				assessment.athlete.team = this.teamsCache.get(assessment.athlete.currentTeam);
				if( this.IsEmpty(assessment.athlete.team) ) assessment.athlete.loadTeams(this.teamsCache);
				assessment.team = assessment.athlete.team;
				this.teamsCache.set(assessment.team);

				if( this.IsNotEmpty(assessment.team?.organizationId) ) {
					assessment.organization = this.orgsCache.get(assessment.team.organizationId);
					if( this.IsEmpty(assessment.organization?.id) ) {
						assessment.organization = await organizationApi.publicFindById(assessment.team.organizationId);
					}
					this.orgsCache.set(assessment.organization);
				}

				if( this.IsEmpty(assessment.physical) ) {
					assessment.physical = await athleteAssessmentDataApi.findById(assessment.assessmentId);
				}
				if( isNewPhysical ) {
					assessment.physical.athleteId = assessment.athlete.id;
					assessment.physical.teamId = assessment.team?.id;
					assessment.physical.organizationId = assessment.organization?.id;
					assessment.physical.dateCreated = new Date();
					assessment.physical.assessmentDate = assessment.event.date;
					assessment.physical.competitiveLevel = 3;
					assessment.physical.gender = this.genderType(assessment.athlete.gender);
					assessment.physical.dominantFoot = assessment.athlete.dominantSide;
					assessment.physical.playingPosition = assessment.athlete.primaryPosition;
					assessment.physical.dateOfBirth = assessment.athlete.BirthDate;
					assessment.Compute();
					await athleteAssessmentDataApi.save(assessment.physical);
				}

				if( this.IsEmpty(assessment.idcolor) ) assessment.idcolor = assessment.athlete.team?.jerseyPrimary?.color;
				if( this.IsEmpty(assessment.idnumber) ) assessment.idnumber = assessment.athlete.PlayerNumber;

				return assessment;
			})
		);

		this.dataItems = this.athleteAssessments;
		this.isLoaded = true;
		this.isLoading = false;
	}
    async loadTable() {
        const backupTableOptions = {...this.tableOptions};

		// filter by group
		this.filteredParticipants = this.currentEvent.participants;
		if( this.IsNotEmpty(this.groupFilter) ) this.filteredParticipants = this.filteredParticipants.filter(p => p.ticketId === this.groupFilter);
		if( this.IsNotEmpty(this.search) ) this.filteredParticipants = this.filteredParticipants.filter(p => (this.IsNotEmpty(p.tags) && p.tags.includes(this.search.toLowerCase())));

        await this.loadAthletes();
		await this.updateTags();
		this.dataItemsCount = this.filteredParticipants.length;

        this.tableOptions = {...backupTableOptions};
    }
	async updateTags() {
		try {
			let saveRequired: boolean = false;
			for(const attendee of this.currentEvent.participants) {
				const assessment = this.athleteAssessments.find(a => a.athleteId === attendee.attendeeId);
				if( this.IsEmpty(assessment) ) continue;

				assessment.UpdateTags();
				if( attendee.tags !== assessment.tags ) {
					attendee.tags = assessment.tags;
					saveRequired = true;
				}
			}
			if( saveRequired ) {
				await baEventApi.patch({id: this.currentEvent.id, participants: this.currentEvent.participants});
			}
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error updating tags: ${e}`});
		}
	}

	isEventLoading: boolean = false;
	allEvents: Array<BAEventModel>;
	currentEvent: BAEventModel;
	async loadEvent() {
		this.isEventLoading = true;
		try {
			const response = await baEventApi.queryAll({}, {sort: {fields: [{field:'date', desc:true}]}} )
			this.allEvents = response.docs;
			if( response.total > 0 ) {
				for(const ev of this.allEvents ) {
					if( isDateWithinRange(ev.date) ) this.currentEvent = ev;
				}
				if( this.IsEmpty(this.currentEvent) ) this.currentEvent = this.allEvents[0];
				this.currentEvent.loadEx();
			}
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
		this.isEventLoading = false;
	}
	get PageLoading(): boolean {
		return this.TableLoading || this.isEventLoading;
	}

	currentAssessment: EventAssessmentDataModel;

	isEditingJersey: boolean = false;
	backupJersey: {idcolor: string, idnumber: string} = {idcolor: "#000000", idnumber: "0"};
	onClickJersey(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupJersey = {idcolor: this.currentAssessment.idcolor, idnumber: this.currentAssessment.idnumber};
		this.isEditingJersey = true;
	}
	onChangeJerseyColor(color: string) {
		this.currentAssessment.idcolor = color;
		this.onAcceptEditJersey();
	}
	onCancelEditJersey() {
		this.currentAssessment.idcolor = this.backupJersey.idcolor;
		this.currentAssessment.idnumber = this.backupJersey.idnumber;
		this.isEditingJersey = false;
	}
	async onAcceptEditJersey() {
		await this.saveAssessment(this.currentAssessment);
		this.isEditingJersey = false;
	}

	isSavingWithoutCheckin: boolean = false;
	isViewCheckinWarning: boolean = false;
	isStationCheckin: boolean = false;
	validFormCheckin: boolean = false;
	backupCheckin: {checkedIn: boolean, weight: number, height: number, sittingHeight: number, gender: Gender, position: string, dominant: string, notes: string};
	errorCheckin: string = '';
	onStationCheckin(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupCheckin = {
			checkedIn: assessment.checkedIn,
			weight: assessment.physical.mass,
			height: assessment.physical.standingHeight,
			sittingHeight: assessment.physical.sittingHeightWithBox,
			gender: assessment.physical.gender,
			position: assessment.physical.playingPosition,
			dominant: assessment.physical.dominantFoot,
			notes: assessment.notes,
		}
		this.isStationCheckin = true;
	}
	onCancelCheckin() {
		this.currentAssessment.checkedIn = this.backupCheckin.checkedIn;
		this.currentAssessment.physical.mass = this.backupCheckin.weight;
		this.currentAssessment.physical.standingHeight = this.backupCheckin.height;
		this.currentAssessment.physical.sittingHeightWithBox = this.backupCheckin.sittingHeight;
		this.currentAssessment.physical.gender = this.backupCheckin.gender;
		this.currentAssessment.physical.playingPosition = this.backupCheckin.position;
		this.currentAssessment.physical.dominantFoot = this.backupCheckin.dominant;
		this.currentAssessment.notes = this.backupCheckin.notes;
		this.isStationCheckin = false;
	}
	async onAcceptCheckout() {
		this.currentAssessment.checkedIn = false;
		this.backupCheckin.checkedIn = false;
		this.backupSprints.checkedIn = false;
		this.backupJumpsDJ.checkedIn = false;
		this.backupJumpsCMJ.checkedIn = false;
		this.isViewCheckinWarning = false;
		await this.saveAssessment(this.currentAssessment);
		this.isStationCheckin = false;
	}
	async onAcceptCheckin() {
		this.currentAssessment.checkedIn = true;
		this.backupCheckin.checkedIn = true;
		this.backupSprints.checkedIn = true;
		this.backupJumpsDJ.checkedIn = true;
		this.backupJumpsCMJ.checkedIn = true;
		this.isViewCheckinWarning = false;
		await this.saveAssessment(this.currentAssessment);
		this.isStationCheckin = false;
		this.isSavingWithoutCheckin = false;
	}
	async onCheckinRequired(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.isViewCheckinWarning = true;
	}
	async onQuickCheckin() {
		this.currentAssessment.checkedIn = true;
		this.currentAssessment.physical.mass = this.currentAssessment.athlete.weight;
		this.currentAssessment.physical.standingHeight = this.currentAssessment.athlete.height;
		await this.onAcceptCheckin();
	}

	isStationSprints: boolean = false;
	validFormSprints: boolean = false;
	backupSprints: {checkedIn: boolean, sprints: Array<Sprint>, bestSprint: number, notes: string}
	errorSprints: string = '';
	onStationSprints(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupSprints = {
			checkedIn: assessment.checkedIn,
			sprints: cloneDeep(assessment.sprints),
			bestSprint: assessment.bestSprint,
			notes: assessment.notes,
		}
		this.isStationSprints = true;
	}
	onCancelSprints() {
		this.currentAssessment.checkedIn = this.backupSprints.checkedIn;
		this.currentAssessment.sprints = cloneDeep(this.backupSprints.sprints);
		this.currentAssessment.bestSprint = this.backupSprints.bestSprint;
		this.currentAssessment.notes = this.backupSprints.notes;
		this.errorSprints = '';
		this.isStationSprints = false;
	}
	async onAcceptSprints() {
		if( !this.validFormSprints ) {
			this.errorSprints = `Please enter sprint time achieved by ${this.currentAssessment.FullName}`
			return;
		}
		await this.currentAssessment.ComputeBestSprint();
		await this.saveAssessment(this.currentAssessment);
		this.errorSprints = '';
		this.isStationSprints = false;
	}
	async onChangeSprint(sprint: Sprint) {
		if( isEmpty(sprint) ) return;

		this.debounceCallback('changeSprint', async () => {
			await this.currentAssessment.ComputeBestSprint();
		});
	}
	async onClearSprint(sprint: Sprint) {
		sprint.tenMeters = undefined;
		sprint.twentyMeters = undefined;
		sprint.thirtyFiveMeters = undefined;
		await this.onChangeSprint(sprint);
	}
	async onDeleteSprint(sprint: Sprint) {
		const index = this.currentAssessment.sprints.findIndex(s => s.trial === sprint.trial);
		if( index >= 0 ) this.currentAssessment.sprints.splice(index, 1);

		this.currentAssessment.sprints.forEach((sprint, index) => sprint.trial = index + 1);
		await this.currentAssessment.ComputeBestSprint()
	}

	isStationJumpDJ: boolean = false;
	validFormJumpDJ: boolean = false;
	backupJumpsDJ: {checkedIn: boolean, jumps: Array<JumpDJ>, bestJumpDJ: number, notes: string}
	errorJumpsDJ: string = '';
	onStationJumpDJ(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupJumpsDJ = {
			checkedIn: assessment.checkedIn,
			jumps: cloneDeep(assessment.jumpsDJ),
			bestJumpDJ: assessment.bestJumpDJ,
			notes: assessment.notes,
		}
		this.isStationJumpDJ = true;
	}
	onCancelJumpDJ() {
		this.currentAssessment.checkedIn = this.backupJumpsDJ.checkedIn;
		this.currentAssessment.jumpsDJ = cloneDeep(this.backupJumpsDJ.jumps);
		this.currentAssessment.bestJumpDJ = this.backupJumpsDJ.bestJumpDJ;
		this.currentAssessment.notes = this.backupJumpsDJ.notes;
		this.errorJumpsDJ = '';
		this.isStationJumpDJ = false;
	}
	async onAcceptJumpDJ() {
		if( !this.validFormJumpDJ ) {
			this.errorJumpsDJ = `Please enter jump data achieved by ${this.currentAssessment.FullName}`
			return;
		}
		await this.saveAssessment(this.currentAssessment);
		this.errorJumpsDJ = '';
		this.isStationJumpDJ = false;
	}
	async onChangeJumpDJ(jump: JumpDJ) {
		if( isEmpty(jump) ) return;

		this.debounceCallback('changeJumpDJ', async () => {
			await this.currentAssessment.ComputeBestJumpDJ();
		});
	}
	async onClearJumpDJ(jump: JumpDJ) {
		jump.height = undefined;
		jump.contactTime = undefined;
		await this.onChangeJumpDJ(jump);
	}
	async onDeleteJumpDJ(jump: JumpDJ) {
		const index = this.currentAssessment.jumpsDJ.findIndex(s => s.trial === jump.trial);
		if( index >= 0 ) this.currentAssessment.jumpsDJ.splice(index, 1);

		this.currentAssessment.jumpsDJ.forEach((jump, index) => jump.trial = index + 1);
		await this.currentAssessment.ComputeBestJumpDJ()
	}

	isStationJumpCMJ: boolean = false;
	validFormJumpCMJ: boolean = false;
	backupJumpsCMJ: {checkedIn: boolean, jumps: Array<JumpCMJ>, bestJumpCMJ: number, notes: string}
	errorJumpsCMJ: string = '';
	onStationJumpCMJ(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupJumpsCMJ = {
			checkedIn: assessment.checkedIn,
			jumps: cloneDeep(assessment.jumpsCMJ),
			bestJumpCMJ: assessment.bestJumpCMJ,
			notes: assessment.notes,
		}
		this.isStationJumpCMJ = true;
	}
	onCancelJumpCMJ() {
		this.currentAssessment.checkedIn = this.backupJumpsCMJ.checkedIn;
		this.currentAssessment.jumpsCMJ = cloneDeep(this.backupJumpsCMJ.jumps);
		this.currentAssessment.bestJumpCMJ = this.backupJumpsCMJ.bestJumpCMJ;
		this.currentAssessment.notes = this.backupJumpsCMJ.notes;
		this.errorJumpsCMJ = '';
		this.isStationJumpCMJ = false;
	}
	async onAcceptJumpCMJ() {
		if( !this.validFormJumpCMJ ) {
			this.errorJumpsCMJ = `Please enter jump height achieved by ${this.currentAssessment.FullName}`
			return;
		}
		await this.saveAssessment(this.currentAssessment);
		this.errorJumpsCMJ = '';
		this.isStationJumpCMJ = false;
	}
	async onChangeJumpCMJ(jump: JumpCMJ) {
		if( isEmpty(jump) ) return;
		
		this.debounceCallback('changeJumpCMJ', async () => {
			await this.currentAssessment.ComputeBestJumpCMJ();
		});
	}
	async onClearJumpCMJ(jump: JumpCMJ) {
		jump.height = undefined;
		await this.onChangeJumpCMJ(jump);
	}
	async onDeleteJumpCMJ(jump: JumpCMJ) {
		const index = this.currentAssessment.jumpsCMJ.findIndex(s => s.trial === jump.trial);
		if( index >= 0 ) this.currentAssessment.jumpsCMJ.splice(index, 1);

		this.currentAssessment.jumpsCMJ.forEach((jump, index) => jump.trial = index + 1);
		await this.currentAssessment.ComputeBestJumpCMJ()
	}

	isStationYoYo: boolean = false;
	validFormYoYo: boolean = false;
	backupYoYo: {checkedIn: boolean, yoyos: Array<YoYo>, bestYoYo: number, notes: string}
	errorYoYo: string = '';
	onStationYoYo(assessment: EventAssessmentDataModel) {
		this.currentAssessment = assessment;
		this.backupYoYo = {
			checkedIn: assessment.checkedIn,
			yoyos: cloneDeep(assessment.yoyos),
			bestYoYo: assessment.bestYoYo,
			notes: assessment.notes,
		}
		this.isStationYoYo = true;
	}
	onCancelYoYo() {
		this.currentAssessment.checkedIn = this.backupYoYo.checkedIn;
		this.currentAssessment.yoyos = cloneDeep(this.backupYoYo.yoyos);
		this.currentAssessment.bestYoYo = this.backupYoYo.bestYoYo;
		this.currentAssessment.notes = this.backupYoYo.notes;
		this.errorYoYo = '';
		this.isStationYoYo = false;
	}
	async onAcceptYoYo() {
		if( !this.validFormYoYo ) {
			this.errorYoYo = `Please enter Yo-Yo stage achieved by ${this.currentAssessment.FullName}`
			return;
		}
		await this.saveAssessment(this.currentAssessment);
		this.errorYoYo = '';
		this.isStationYoYo = false;
	}
	async onChangeYoYo(yoyo: YoYo) {
		if( isEmpty(yoyo) ) return;

		this.debounceCallback('changeYoYo', async () => {
			await this.currentAssessment.ComputeBestYoYo();
		});
	}
	async onClearYoYo(yoyo: YoYo) {
		yoyo.stage = undefined;
		await this.onChangeYoYo(yoyo);
	}
	async onDeleteYoYo(yoyo: YoYo) {
		const index = this.currentAssessment.yoyos.findIndex(y => y.trial === yoyo.trial);
		if( index >= 0 ) this.currentAssessment.yoyos.splice(index, 1);

		this.currentAssessment.yoyos.forEach((yoyo, index) => yoyo.trial = index + 1);
		await this.currentAssessment.ComputeBestYoYo()
	}

	async saveAssessment(assessment: EventAssessmentDataModel, quiet: boolean = true) {
		try {
			assessment.changesPending = false;
			assessment.Compute();
			assessment.UpdateTags();
			await athleteAssessmentDataApi.save(assessment.physical);
			await eventAssessmentDataApi.save(assessment);

			// update athlete
			await athleteApi.patch({
				id: assessment.athlete.id,
				height: assessment.physical.standingHeight,
				weight: assessment.physical.mass,
			})

			if( !quiet ) notificationStore.pushSnackbarSuccess({message: `Event Assessment for ${assessment.FullName} updated`});
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}

	groupFilter: string = '';
	isStationAllCheckins: boolean = false;
	isStationAllSprints: boolean = false;
	isStationAllJumpsDJ: boolean = false;
	isStationAllJumpsCMJ: boolean = false;
	isStationAllYoYos: boolean = false;
	get TotalCheckedIn(): number {
		return this.athleteAssessments.filter(a => a.checkedIn).length;
	}
	async onCheckinStationClickCheckIn(assessment: EventAssessmentDataModel) {
		assessment.checkedIn = !assessment.checkedIn;
		await this.onCheckinStationCheckIn(assessment);
	}
	async onCheckinStationCheckIn(assessment: EventAssessmentDataModel) {
		await this.saveAssessment(assessment, false);
	}
	async onCheckinStationChangeData(assessment: EventAssessmentDataModel) {
		assessment.changesPending = true;
		if( !assessment.checkedIn ) return;
		if( this.IsEmpty(assessment.physical.mass) ) return;
		if( this.IsEmpty(assessment.physical.standingHeight) ) return;
		if( this.IsEmpty(assessment.physical.sittingHeightWithBox) ) return;
		await this.saveAssessment(assessment, false);
	}
	async onCheckinStationSave(assessment: EventAssessmentDataModel) {
		if( !assessment.checkedIn ) {
			this.currentAssessment = assessment;
			this.isSavingWithoutCheckin = true;
		}
		else await this.saveAssessment(assessment);
	}

	get TotalSprinted(): number {
		return this.athleteAssessments.filter(a => a.hasSprint).length;
	}
	async onSprintStationCheckIn(assessment: EventAssessmentDataModel) {
		await this.saveAssessment(assessment, false);
	}
	async onSprintStationChangeData(assessment: EventAssessmentDataModel) {
		assessment.changesPending = true;
		if( this.IsEmpty(assessment.CurrentSprint.tenMeters) ) return;
		if( this.IsEmpty(assessment.CurrentSprint.twentyMeters) ) return;
		if( this.IsEmpty(assessment.CurrentSprint.thirtyFiveMeters) ) return;
		await assessment.ComputeBestSprint();
		await this.saveAssessment(assessment, false);
	}
	async onSprintStationSave(assessment: EventAssessmentDataModel) {
		if( !assessment.checkedIn ) {
			this.currentAssessment = assessment;
			this.isSavingWithoutCheckin = true;
		}
		else await this.saveAssessment(assessment);
	}

	get TotalJumpsDJ(): number {
		return this.athleteAssessments.filter(a => a.hasJumpDJ).length;
	}
	async onJumpDJStationCheckIn(assessment: EventAssessmentDataModel) {
		await this.saveAssessment(assessment, false);
	}
	async onJumpDJStationChangeData(assessment: EventAssessmentDataModel) {
		assessment.changesPending = true;
		if( this.IsEmpty(assessment.CurrentJumpDJ.height) ) return;
		if( this.IsEmpty(assessment.CurrentJumpDJ.contactTime) ) return;
		await assessment.ComputeBestJumpDJ();
		await this.saveAssessment(assessment, false);
	}
	async onJumpDJStationSave(assessment: EventAssessmentDataModel) {
		if( !assessment.checkedIn ) {
			this.currentAssessment = assessment;
			this.isSavingWithoutCheckin = true;
		}
		else await this.saveAssessment(assessment);
	}

	get TotalJumpsCMJ(): number {
		return this.athleteAssessments.filter(a => a.hasJumpCMJ).length;
	}
	async onJumpCMJStationCheckIn(assessment: EventAssessmentDataModel) {
		await this.saveAssessment(assessment, false);
	}
	async onJumpCMJStationChangeData(assessment: EventAssessmentDataModel) {
		assessment.changesPending = true;
		if( this.IsEmpty(assessment.CurrentJumpCMJ.height) ) return;
		if( this.IsEmpty(assessment.physical?.mass) ) return;
		await assessment.ComputeBestJumpCMJ();
		await this.saveAssessment(assessment, false);
	}
	async onJumpCMJStationSave(assessment: EventAssessmentDataModel) {
		if( !assessment.checkedIn ) {
			this.currentAssessment = assessment;
			this.isSavingWithoutCheckin = true;
		}
		else await this.saveAssessment(assessment);
	}

	get TotalYoYo(): number {
		return this.athleteAssessments.filter(a => a.hasYoYo).length;
	}
	async onYoYoStationCheckIn(assessment: EventAssessmentDataModel) {
		await this.saveAssessment(assessment, false);
	}
	async onYoYoStationChangeData(assessment: EventAssessmentDataModel) {
		assessment.changesPending = true;
		if( this.IsEmpty(assessment.CurrentYoYo.stage) ) return;
		await assessment.ComputeBestYoYo();
		await this.saveAssessment(assessment, false);
	}
	async onYoYoStationSave(assessment: EventAssessmentDataModel) {
		if( !assessment.checkedIn ) {
			this.currentAssessment = assessment;
			this.isSavingWithoutCheckin = true;
		}
		else await this.saveAssessment(assessment);
	}

	isChangingEvent: boolean = false;
	isSelectingEvent: boolean = false;
	selectedEvent: BAEventModel;
	async onChangeEventBegin() {
		this.selectedEvent = this.currentEvent;
		this.isChangingEvent = true;
	}
	async onAcceptChangeEvent() {
		this.currentEvent = this.selectedEvent;
		await this.currentEvent.loadEx();
		this.updateTable();
		this.isChangingEvent = false;
	}
	async onCancelChangeEvent() {
		this.isChangingEvent = false;
	}
	async onChangeEventSelected() {
		this.isSelectingEvent = true;
		await this.selectedEvent.loadEx();
		this.isSelectingEvent = false;
	}

	isExportingData: boolean = false;
	async exportData() {
		const headersMeasured: Array<String> = [
			"Test Date (DD Month YYYY)",
			"First Name",
			"Last Name",
			"Email Address",
			"Date Of Birth (Day Month YYYY)",
			"Weight (kg)",
			"Height (cm)",
			"Sitting Height (cm)",
			"Counter Movement Jump Height (cm)",
			"Drop Jump Contact Time (s)",
			"Drop Jump Height (cm)",
			"10m Sprint Time (s)",
			"20m Sprint Time (s)",
			"35m Sprint Time (s)",
			"10m Sprint Time (s)",
			"20m Sprint Time (s)",
			"35m Sprint Time (s)",
			"Yo-Yo Intermittent Recovery Test (stage)",
			"10m Sprint Time (s)",
			"20m Sprint Time (s)",
			"35m Sprint Time (s)",
			"Gender ( M / F )",
			"Dominant Foot ( L / R / B )",
			"Playing Position Goalkeeper / Defender / Midfielder / Forward",
			"Playing Level ( U10 - Adult )",
			"Competitive Level ( 1 - 5 )",
			"Organization Name",
			"Team Name",
			"Athlete Short ID",
		];
		const headersComputed: Array<String> = [
			"Age (years)",
			"Age (month)",
			"Month (decimal)",
			"Age (yr+mth)",
			"True Sitting Height (cm)",
			"Box Height (cm)",
			"Leg Length",
			"height",
			"mass",
			"sit ht",
			"leg*trunk",
			"age*leg",
			"age*sit ht",
			"age*mass",
			"wt/ht ratio",
			"BMI",
			"Maturity Offset",
			"Age of Peak Height Velocity (years) age+maturity offset",
			"Developmental Category (Early/Average/Late)",
			"Power (W)",
			"Reactive Strength Index",
			"Acceleration (m/s)",
			"20-35m Split Time (s)",
			"Max Speed (m/s)",
			"Yo-Yo Intermittent Recovery Test (Distance)",
			"Maximal Aerobic Velocity (Km/Hr)",
		]
		const headers: Array<String> = [...headersMeasured, ...headersComputed];

		this.athleteAssessments.forEach(assessment => assessment.Compute() );
		const csvRowsMeasured = this.athleteAssessments.map(assessment => [
			this.formatDateEval(assessment.event?.date),
			assessment.athlete?.firstName,
			assessment.athlete?.lastName,
			assessment.Email,
			assessment.athlete?.BirthDate,
			assessment.physical?.mass,
			assessment.physical?.standingHeight,
			assessment.physical?.sittingHeightWithBox,
			assessment.BestJumpCMJ?.height,
			assessment.BestJumpDJ?.contactTime,
			assessment.BestJumpDJ?.height,
			this.IsNotEmpty(assessment.sprints)? assessment.sprints[0].tenMeters : '',
			this.IsNotEmpty(assessment.sprints)? assessment.sprints[0].twentyMeters : '',
			this.IsNotEmpty(assessment.sprints)? assessment.sprints[0].thirtyFiveMeters : '',
			(this.IsNotEmpty(assessment.sprints) && assessment.sprints.length > 1)? assessment.sprints[1].tenMeters : '',
			(this.IsNotEmpty(assessment.sprints) && assessment.sprints.length > 1)? assessment.sprints[1].twentyMeters : '',
			(this.IsNotEmpty(assessment.sprints) && assessment.sprints.length > 1)? assessment.sprints[1].thirtyFiveMeters : '',
			assessment.BestYoYo?.stage,
			assessment.BestSprint?.tenMeters,
			assessment.BestSprint?.twentyMeters,
			assessment.BestSprint?.thirtyFiveMeters,
			assessment.physical?.gender,
			assessment.physical?.dominantFoot,
			assessment.physical?.playingPosition,
			assessment.physical?.playingLevel,
			assessment.physical?.competitiveLevel,
			assessment.organization?.name,
			assessment.team?.name,
			assessment.athlete?.shortId,
		]);
		const csvRowsComputed = this.athleteAssessments.map(assessment => [
			assessment.physical?.ageYear,
			assessment.physical?.ageMonthRemainder,
			assessment.physical?.ageMonthDecimal,
			assessment.physical?.age,
			assessment.physical?.trueSittingHeight,
			assessment.physical?.boxHeight,
			assessment.physical?.legLength,
			assessment.physical?.standingHeight,
			assessment.physical?.mass,
			assessment.physical?.sittingHeightWithBox,
			assessment.physical?.legTrunk,
			assessment.physical?.ageLeg,
			assessment.physical?.ageSittingHeight,
			assessment.physical?.ageMass,
			assessment.physical?.massHeightRatio,
			assessment.physical?.bodyMassIndex,
			assessment.physical?.maturityOffset,
			assessment.physical?.ageOfPeakHeightVelocity,
			assessment.physical?.developmentalCategory,
			assessment.physical?.power,
			assessment.physical?.reactiveStrengthIndex,
			assessment.physical?.acceleration,
			assessment.physical?.twentyToThirtyFiveMeterSplit,
			assessment.physical?.speed,
			assessment.physical?.yoyoIntermittentRecoveryTestDistance,
			assessment.physical?.maximalAerobicVelocity,
		]);
		const csvRows = csvRowsMeasured.map((meas, index) => [...meas, ...csvRowsComputed[index]]);

		this.exportCSV("AthleteAssessmentData.csv", headers, csvRows);
		this.exportCSV("AthleteAssessmentData-Measured.csv", headersMeasured, csvRowsMeasured);
	}
	async exportCSV(filename: string, headers: Array<String>, data: Array<any>) {
		const csvContent = [headers.join(","), ...data.map(r => r.join(","))].join("\n");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		// Create a link and trigger download
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();

		// Cleanup
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	}

	async onExportData() {
		this.isExportingData = true;
		this.exportData();
		this.isExportingData = false;
	}

	get RowsPerPageItems() {
		return [
			{ name: '10', value: 10 },
			{ name: '25', value: 25 },
			{ name: '100', value: 100 },
			{ name: '250', value: 250 },
		]
	}
}
